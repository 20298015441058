<template>
  <div class="wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">企业档案</el-breadcrumb-item>
      <el-breadcrumb-item>企业档案馆详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- <div class="updata-time">
      更新时间：2020-12-19 | 状态：正常
      <el-button type="primary" class="btn" @click="dialogVisible=true">我要纠错</el-button>
    </div> -->
    <div class="concent">
      <!-- 工商信息 -->
      <section class="section3">
        <div class="sectionView clearfix dark">
          <div class="top clearfix">
            <div class="title fl">企业档案</div>
            <el-button type="text" class="fr" @click="sectionShow1 = true" v-if="!sectionShow1">
              展开
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-button type="text" class="fr" @click="sectionShow1 = false" v-if="sectionShow1">
              收起
              <i class="el-icon-arrow-up el-icon--right"></i>
            </el-button>
          </div>
          <div class="center" >
              <iframe class="iframe" :src="`https://10.36.204.53:8886/#/archivesDetail?socialCode=${socialCode}&token=${token}`"/>
              <!-- <iframe class="iframe" :src="`http://localhost:8080/#/archivesDetail?socialCode=${socialCode}&token=${token}`"/> -->
          </div>
        </div>
      </section>
    </div>

    <el-dialog :visible.sync="dialogVisible" width="600px" center>
      <div class="dialog-title mb20">企业档案馆数据来源均通过政府相关部门获取，若出现与实际情况不符，请联系我们，我们将及时做出修正。</div>
      <label class="bold">请填写纠错信息</label>
      <el-input type="textarea" :rows="5" v-model="text"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false" class="btn">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
//
import { mapState } from 'vuex';
var echarts = require('echarts');
import { getToken } from '@/http/auth';
window.getToken = getToken
export default {
  name: 'demandDetails',
  data() {
    return {
      sectionShow1:true,
      token:getToken(),
      dialogVisible:false,
      socialCode:localStorage.getItem("SOCIAL_CODE")
    }
  },
  methods: {
  
  },
  mounted() {
    
  },
  created() {},
 
};
</script>


<style lang="scss" scoped>
.dialog-title{
  padding-right: 20px;
  margin-top: -37px;
}
body,div{
   &::-webkit-scrollbar {
    display: none !important;
    width:0 !important;   /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
     -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
   }
}

.wrapper {
  position: relative;
   &::-webkit-scrollbar {
    display: none;
  }
}
.updata-time {
  position: absolute;
  top: 70px;
  right: 104px;
  color: #666666;
  line-height: 20px;
}
.btn {
  margin-left: 16px;
  background-color: #0e459c;
}
.el-breadcrumb {
  margin: 10px auto 26px;
  line-height: 40px;
}
.sectionView {
  padding: 16px 20px 24px;
  background-color: #fff;
  border-radius: 5px;
  height: 70vh;
  overflow-y:scroll ;
  background: #092846;
  box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
// box-shadow:  20px 20px 60px #08223c,
//              -20px -20px 60px #0a2e51;
  .center{
    height: 100vh;
  }
  &.dark{
   background-color: #092846;
   
   & .title{
     color:white;
   }

  }
}

.wrapper {
  padding-left: 83px;
  padding-right: 83px;
  padding-bottom: 15px;
  margin: 0 auto;
  min-width: 1200px;
}
.wrapper section {
  margin-bottom: 16px;
}

body {
  overflow: unset;
  font-size: 14px;
}

.table-bordered {
  border: 1px solid #ddd;
}

section {
  margin-bottom: 16px;
}

.w100 {
  width: 100%;
}

.w50 {
  width: 50%;
}

.relation-chart {
  height: 310px;
}

.chartView {
  background-color: #fff;
  margin-bottom: 26px;
}

.chartView .top {
  padding-bottom: 15px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 16px;
}

.chartView .item-box {
  width: calc((100% - 40px) * 0.33333);
  height: 176px;
  border-radius: 0px 0px 4px 4px;
  background-image: linear-gradient(180deg, #f6faff 0%, #e9f4ff 100%);
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-right: 20px;
}

.chartView .item-box:nth-child(3n + 1) {
  margin-right: 0;
}

.chartView .item-box:nth-child(n + 4) {
  margin-bottom: 0;
}

.chartView .item-box .item-top {
  border-top: 2px solid #4393f8;
  margin-bottom: 16px;
  position: relative;
}

.chartView .item-box .item-top .top-bg {
  height: 0px;
  width: 172px;
  border-top: 40px solid #4393f8;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -96px;
}

.chartView .center p {
  line-height: 30px;
}

.chartView .item-box .item-bottom {
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
  margin-top: 22px;
  background: #cee4ff;
  border-radius: 0px 0px 4px 4px;
}

.chartView .top .time {
  font-size: 14px;
  color: #666666;
  text-align: right;
  line-height: 20px;
  font-weight: 600;
}

.chartView .top .img {
  width: 16px;
  height: 16px;
  margin-top: 10px;
  cursor: pointer;
}

.chartView .item-box .item-title {
  height: 40px;
  line-height: 40px;
  font-family: MicrosoftYaHei-Bold;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  z-index: 1;
  position: relative;
}

.content .header {
  width: 100%;
}

.content .header .name {
  font-size: 20px;
  font-family: MicrosoftYaHei-Bold;
  color: #333333;
  font-weight: bold;
}

.sectionView .title {
  font-family: 'MicrosoftYaHei-Bold';
  font-size: 26px;
  font-weight: 600;
  color: #333333;
  line-height: 36px;
  position: relative;
  padding-left: 14px;
}

.sectionView .title:before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 20px;
  background: #4393f8;
  border-radius: 3px;
  position: absolute;
  top: 10px;
  left: 0;
}

.sectionView .top {
  padding-bottom: 15px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 16px;
}

.sectionView .center {
  // padding: 0 20px;

  .iframe{
    width: 100%;
    height: 100%;
    border:none;
  }
}

.info-title {
  font-size: 14px;
  color: #666;
  font-weight: bold;
  position: relative;
  padding: 17px 20px;
  background: #f3f3f5 !important;
  border: 1px solid #ddd;
}

.info-box .info-li {
  width: 33.33%;
  color: #333;
  line-height: 30px;
}

.section2 .rader-box {
  height: 380px;
}

.section2 .img-box {
  height: 380px;
  padding: 40px 45px 37px 57px;
  background: rgba(236, 245, 255, 0.46);
}

.showDetail {
  width: 70px;
  height: 32px;
  background: #0e459c;
  border-radius: 4px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  right: 17px;
  position: absolute;
  top: 50%;
  margin-top: -16px;
}

.show-hide-btn {
  font-size: 14px;
  color: #0e459c;
  width: 70px;
  text-align: center;
  cursor: pointer;
}

.table-tag {
  padding: 0 10px;
  display: inline-block;
  height: 24px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 8px;
  margin-right: 8px;
}

.table-tag.blue {
  background: #f0fdff;
  border: 1px solid #409eff;
  border-radius: 4px;
  color: #409eff;
}

.table-tag.red {
  background: #ffeded;
  border: 1px solid #f56c6c;
  border-radius: 4px;
  color: #f56c6c;
}

.chartbox .chart-title {
  padding: 15px 0;
  font-size: 16px;
  color: #000000;
  line-height: 20px;
  border-bottom: 1px solid #dddddd;
}

.dn {
  display: none;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.table1,
.table1 th,
.table1 td {
  border: 1px solid #ddd;
}

.table2 tr {
  border-bottom: 1px solid #ddd;
}

th {
  text-align: left;
}

th,
td {
  padding: 17px 21px;
  border-width: 1px;
}

.table1 tr td:nth-child(2n-1) {
  background: #fafafa !important;
  color: #666666;
  width: 20%;
}

table tr:last-child td {
  background-color: #fff !important;
}

.table2 {
  margin-bottom: 0 !important;
  border: 1px solid #ddd;
  border-top: none;
}

.borderStyle {
  padding: 17px 20px;
  border: 1px solid #ddd;
  border-top: none;
}

.table,
.table tr:first-child th,
.table tr:first-child td {
  border-top: none !important;
}

th {
  background: #fafafa !important;
  font-weight: bold;
}

.section5 .info-title:not(:first-child) {
  border-top: none;
}

.uptime {
  font-size: 14px;
  color: #666666;
  margin-right: 36px;
  line-height: 40px;
}
</style>